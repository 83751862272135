<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Thông tin chương trình tính hoa hồng ngành hàng điện thoại'"
        >
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          label="Tên chương trình"
                          label-for="input-name"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-input
                            size="sm"
                            v-model="$v.mainModel.name.$model"
                            placeholder="Nhập tên"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Mô tả">
                          <b-form-textarea
                            v-model="mainModel.description"
                            placeholder="Nhập mô tả"
                            rows="5"
                            :max-length="255"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          label-align-md="left"
                          required
                          class="required-control"
                          label="Từ ngày"
                        >
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.startDate"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label-align-md="left"
                          required
                          class="required-control"
                          label="Đến ngày"
                        >
                          <date-picker
                            placeholder="Đến ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.endDate"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Trạng thái">
                          <b-form-select
                            size="sm"
                            v-model="mainModel.status"
                            :options="listStatus"
                            required
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <hr class="hr-text" data-content="" style="font-weight: 600" />
                <h4>Điều kiện đạt gói thưởng</h4>
                <b-row>
                  <b-col md="12">
                    <b-form-group 
                      label="1) Danh mục sản phẩm:"
                      class="col-md-6 pl-0"
                    >
                      <vue-autosuggest
                          v-model="searchCategoryInput"
                          :suggestions="filteredOptionsCategory"
                          @selected="onSelectedSearchCategory"
                          :limit="10"
                          @input="onInputSearchCategory"
                          :input-props="{
                            class: 'autosuggest__input',
                            placeholder: 'Nhập danh mục sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.name }}</div>
                          </template>
                        </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group 
                      label="2) Mục tiêu số lượng:"
                      class="col-md-12 pl-0"
                    >
                      <b-form-input
                        size="sm"
                        type="text"
                        v-model="mainModel.quantityTarget"
                        placeholder="Nhập mục tiêu số lượng"
                        class="text-right col-3"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="3) Mục tiêu lãi gộp (%):"
                      class="col-md-12 pl-0"
                    >
                      <b-form-input
                        size="sm"
                        v-model="mainModel.grossProfitTarget"
                        placeholder="Nhập mục tiêu lãi gộp"
                        class="text-right col-3"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <span>4) Mục tiêu tồn kho (VND):</span>
                  </b-col>
                  <b-col md="12">
                    <b-form-group 
                      class="col-md-12 pl-0"
                    >
                      <b-row>
                        <b-col md="3">
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="mainModel.stockMinTarget"
                            placeholder="Nhập mục tiêu số lượng"
                            class="text-right col-12"
                            v-mask="mask"
                          ></b-form-input>
                        </b-col>
                        <b-col class="text-center" md="1">&lt;=</b-col>
                        <b-col class="text-center" md="2">
                          Gía Trị Tồn Kho 
                        </b-col>
                        <b-col class="text-center" md="1">&lt;=</b-col>
                        <b-col md="3">
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="mainModel.stockMaxTarget"
                            placeholder="Nhập mục tiêu số lượng"
                            class="text-right col-12"
                            v-mask="mask"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12"></b-col>
                </b-row>
                <hr class="hr-text" data-content="" style="font-weight: 600" />
                <h4>Danh sách nhân viên phụ trách</h4>
                <b-row>
                  <b-col md="12">
                    <b-form-group 
                      label="1) Điểm thưởng mỗi sản phẩm (VND):" 
                      class="col-md-12 pl-0" 
                    >
                      <b-row>
                        <b-col md="12">
                          <b-form-radio 
                            v-model="selectedBaseBonusOption" 
                            name="some-radios" 
                            value="basic"
                          >Cở bản</b-form-radio>
                        </b-col>
                        <b-col md="12">
                          <b-row>
                            <b-col md="4">
                              <span>Điểm thưởng</span>
                              <b-form-input
                                size="sm"
                                type="text"
                                v-model="mainModel.baseBonusPoint"
                                placeholder="Nhập điểm thưởng"
                                class="text-right col-12"
                                v-mask="mask"
                                :disabled="selectedBaseBonusOption !== 'basic'"
                                @input="onInputBonusPoint"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="12">---</b-col>
                        <b-col md="12">
                          <b-form-radio 
                            v-model="selectedBaseBonusOption" 
                            name="some-radios" 
                            value="advanced"
                          >Mở rộng</b-form-radio>
                        </b-col>
                        <b-col md="12">
                          <b-row>
                            <b-col md="4">
                              <span>Điểm thưởng bán buôn</span>
                              <b-form-input
                                size="sm"
                                type="text"
                                v-model="mainModel.baseWholesaleBonusPoint"
                                placeholder="Nhập điểm thưởng bán buôn"
                                class="text-right col-12"
                                v-mask="mask"
                                :disabled="selectedBaseBonusOption !== 'advanced'"
                                @input="onInputBonusPoint"
                              ></b-form-input>
                            </b-col>
                            <b-col md="4">
                              <span>Điểm thưởng bán lẻ</span>
                              <b-form-input
                                size="sm"
                                type="text"
                                v-model="mainModel.baseRetailBonusPoint"
                                placeholder="Nhập điểm thưởng bán lẻ"
                                class="text-right col-12"
                                v-mask="mask"
                                :disabled="selectedBaseBonusOption !== 'advanced'"
                                @input="onInputBonusPoint"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-12">
                    <label for="input-code">Tìm nhân viên phụ trách:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchInchargedEmployeeInput"
                          :suggestions="filteredOptionsEmployee"
                          @selected="onSelectedSearchInchargedEmployee"
                          :limit="10"
                          @input="onInputSearchInchargedEmployee"
                          :input-props="{
                            class: 'autosuggest__input',
                            placeholder: 'Nhập tên nhân viên phụ trách',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.name }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2"></b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="listEmployeeInchargeFields"
                        :items="mainModel.listEmployeeIncharge"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(inchargeEmployeeName)="row">
                          <div>{{ row.item.inchargeEmployeeName }}</div>
                        </template>
                        <template v-slot:cell(inchargeEmployeeRewardRate)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.inchargeEmployeeRewardRate"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        
                        <template v-slot:cell(actions)="row">
                          <div class="d-flex justify-content-center">
                            <b-dropdown size="sm" no-caret right>
                              <template slot="button-content">
                                <i
                                  style="font-size: 1rem; padding-right: 0px"
                                  class="flaticon2-settings"
                                ></i>
                              </template>
                              <b-dropdown-item
                                @click="showDeleteInchargeEmployeeAlert(row.item)"
                              >
                                <span style="color: #3f4254; font-size: 12px">
                                  <i
                                    style="font-size: 1rem; color: #d33"
                                    class="flaticon2-rubbish-bin-delete-button"
                                  ></i>
                                  &nbsp; Xóa
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
                <hr class="hr-text" data-content="" style="font-weight: 600" />
                <h4>Danh sách chi phí</h4>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="listFeeFields"
                        :items="mainModel.listFee"
                        v-if="renderComponent"
                      >
                        <template #thead-top="">
                          <b-tr>
                            <b-th></b-th>
                            <b-th>
                              <b-input
                                type="text"
                                size="sm"
                                class="input-style text-right"
                                v-model="creatingFee.name"
                              ></b-input>
                            </b-th>
                            <b-th>
                              <b-input
                                type="text"
                                size="sm"
                                class="input-style text-right"
                                v-model="creatingFee.description"
                              ></b-input>
                            </b-th>
                            <b-th>
                              <b-input
                                type="text"
                                size="sm"
                                class="input-style text-right"
                                v-model="creatingFee.amount"
                                v-mask="mask"
                              ></b-input>
                            </b-th>
                            <b-th>
                              <div class="d-flex justify-content-center">
                                <b-button
                                  @click="onAddFee(creatingFee)"
                                >
                                  <b-icon
                                    style="font-size: 1rem; padding-right: 0px"
                                    icon="patch-plus"
                                  ></b-icon>
                                </b-button>
                              </div>
                            </b-th>
                          </b-tr>
                        </template>

                        <template v-slot:cell(name)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.name"
                          ></b-input>
                        </template>
                        <template v-slot:cell(description)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.description"
                          ></b-input>
                        </template>
                        <template v-slot:cell(amount)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.amount"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <div class="d-flex justify-content-center">
                            <b-dropdown size="sm" no-caret right>
                              <template slot="button-content">
                                <i
                                  style="font-size: 1rem; padding-right: 0px"
                                  class="flaticon2-settings"
                                ></i>
                              </template>
                              <b-dropdown-item
                                @click="showDeleteFeeAlert(row.item)"
                              >
                                <span style="color: #3f4254; font-size: 12px">
                                  <i
                                    style="font-size: 1rem; color: #d33"
                                    class="flaticon2-rubbish-bin-delete-button"
                                  ></i>
                                  &nbsp; Xóa
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <!-- </b-col> -->
                <!-- <b-col lg="2" class="pb-2"> -->
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from '../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from '../../../utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau } from '../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      mainModel: {
        id: 0,
        name: null,
        description: null,
        status: true,
        startDate: new Date(),
        endDate: null,
        brand: null,
        quantityTarget: 0,
        stockMaxTarget: 0,
        stockMinTarget: 0,
        grossProfitTarget: 0,
        baseBonusPoint: 0,
        baseWholesaleBonusPoint: 0,
        baseRetailBonusPoint: 0,
        listEmployeeIncharge: [],
        listFee: [],
      },
      listEmployeeInchargeFields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'inchargeEmployeeName',
          label: 'Nhân viên phụ trách',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inchargeEmployeeRewardRate',
          label: 'Tỷ lệ thưởng/ gói thưởng (%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listFeeFields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'amount',
          label: 'Giá trị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      currentPage: 1,
      searchInchargedEmployeeInput: '',
      filteredOptionsEmployee: [{
        data: []
      }],
      optionsEmployee: [],

      searchCategoryInput: '',
      filteredOptionsCategory: [{
        data: []
      }],

      creatingFee: {
        name: '',
        description: '',
        amount: 0
      },

      isSearching: false,

      selectedBaseBonusOption: 'basic'
    };
  },
  validations: {
    mainModel: {
      startAt: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thưởng hoa hồng ngành hàng điện thoại',
        route: '/reward/reward-accessory-program',
      },
      {
        title: 'Thêm mới chương trình tính hoa hồng ngành hàng điện thoại',
      },
    ]);
  },
  computed: {},
  created() {
    this.getListEmployee();
    this.rewardProgramId = this.$route.query.id;
    if (this.rewardProgramId) {
      this.getRewardProgramById();
    }
  },
  methods: {
    ...getToastInstance(),
    onInputSearchInchargedEmployee: function(text) {
      if (!text) {
        text = '';
      }

      const filteredData = this.optionsEmployee
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit)

        this.filteredOptionsEmployee = [
          {
            data: filteredData
          }
        ];
    },
    onSelectedSearchInchargedEmployee: function (option) {
      this.searchInchargedEmployeeInput = '';
      const item = {
        id: null,
        inchargeEmployeeName: option.item.name,
        inchargeEmployeeId: option.item.id,
        inchargeEmployeeRewardRate: 0
      };
      this.mainModel.listEmployeeIncharge.push(item);
      this.mainModel.listEmployeeIncharge.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onSelectedSearchCategory: function (option) {
      this.searchCategoryInput = '';
      this.mainModel.brand = option.item.id;
    },
    onInputSearchCategory: function (text) {
      this.searchCategoryInput = text;
      this.debounceInputSearchCategory();
    },
    debounceInputSearchCategory: decounce(function () {
      const textSearch = this.searchCategoryInput;
      if (!this.isSearching) {
        this.fetchCategories(textSearch);
      }
    }, TIME_TRIGGER),
    fetchCategories: function (textSearch) {
      this.isSearching = true;
      this.filteredOptionsCategory[0].data = [];
      ApiService.setHeader();
      return ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          const products = data.data;
          this.filteredOptionsCategory[0].data = products;

          this.isSearching = false;
          if (textSearch !== this.searchCategoryInput) {
            const nextTextSearch = this.searchCategoryInput;
            this.fetchCategories(nextTextSearch);
          }
        }
      );
    },
    showDeleteInchargeEmployeeAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (!result.dismiss) {
          this.mainModel.listEmployeeIncharge = _.reject(
            this.mainModel.listEmployeeIncharge,
            (product) => {
              return product.count == item.count;
            }
          );
          this.mainModel.listEmployeeIncharge.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    showDeleteFeeAlert: function (item) {
      Swal.fire({
        title: 'Xóa Chi phí!',
        text: 'Bạn có chắc muốn xóa Chi phí này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (!result.dismiss) {
          this.mainModel.listFee = _.reject(
            this.mainModel.listFee,
            (fee) => {
              return fee.count == item.count;
            }
          );
          this.mainModel.listFee.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    getListEmployee: function () {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          const item = {
            id: element.id,
            name: element.fullName,
          };
          this.optionsEmployee.push(item);
        });
      });
    },
    onBack: function () {
      history.back();
    },
    validateData: async function (data) {
      if (!data.name) {
        this.makeToastFailure('Không được bỏ trống Tên chương trình!');
        return false;
      }

      if (
        !data.startDate
      ) {
        this.makeToastFailure('Không được bỏ trống Từ ngày!');
        return false;
      } 

      if (
        data.startDate && 
        data.endDate &&
        data.endDate < data.startDate
      ) {
        this.makeToastFailure('Từ ngày phải nhỏ hoặc bằng Đến ngày!');
        return false;
      } 

      if (
        data.stockMinTarget && 
        data.stockMaxTarget &&
        data.stockMaxTarget < data.stockMinTarget
      ) {
        this.makeToastFailure('Mục tiêu tồn kho 1 phải nhỏ hoặc bằng Mục tiêu tồn kho 2!');
        return false;
      } 

      if (data.listEmployeeIncharge && data.listEmployeeIncharge.length === 0) {
        this.makeToastFailure('Danh sách nhân viên phụ trách không được phép bỏ trống!');
        return false;
      }

      if (data.baseBonusPoint == 0 && (data.baseWholesaleBonusPoint == 0 || data.baseRetailBonusPoint == 0)) {
        this.makeToastFailure('Không được bỏ trống Điểm thưởng');
        return false;
      }
      return true;
    },
    onSubmit: async function () {
      // format unmask price
      const listEmployeeIncharge = this.mainModel.listEmployeeIncharge.map((element) => {
        return {
          ...element,
          inchargeEmployeeRewardRate: unMaskPrice(element.inchargeEmployeeRewardRate)
        }
      });

      const listFee = this.mainModel.listFee.map((element) => {
        return {
          ...element,
          amount: unMaskPrice(element.amount)
        }
      })
      
      const startDate = this.mainModel.startDate
        ? moment(this.mainModel.startDate, 'DD/MM/YYYY').startOf().valueOf()
        : null;
      const endDate = this.mainModel.endDate
        ? moment(this.mainModel.endDate, 'DD/MM/YYYY').endOf().valueOf()
        : null;
      const data = {
        name: this.mainModel.name,
        description: this.mainModel.description,
        status: this.mainModel.status,
        startDate: startDate,
        endDate: endDate,
        brand: this.mainModel.brand,
        quantityTarget: unMaskPrice(this.mainModel.quantityTarget),
        stockMaxTarget: unMaskPrice(this.mainModel.stockMaxTarget),
        stockMinTarget: unMaskPrice(this.mainModel.stockMinTarget),
        grossProfitTarget: unMaskPrice(this.mainModel.grossProfitTarget),
        baseBonusPoint: unMaskPrice(this.mainModel.baseBonusPoint),
        baseWholesaleBonusPoint: unMaskPrice(this.mainModel.baseWholesaleBonusPoint),
        baseRetailBonusPoint: unMaskPrice(this.mainModel.baseRetailBonusPoint),
        listEmployeeIncharge: listEmployeeIncharge,
        listFee: listFee
      };

      const isValid = await this.validateData(data);
      if (isValid) {
        ApiService.post('rewardPhone/monthProgram', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    getRewardProgramById: function () {
      ApiService.setHeader();
      return ApiService.get(
        'rewardPhone/monthProgram/' + this.rewardProgramId
      ).then((res) => {
        const {
          name,
          description,
          startDate,
          endDate,
          status,
          brand,
          quantityTarget,
          stockMaxTarget,
          stockMinTarget,
          grossProfitTarget,
          baseBonusPoint,
          baseWholesaleBonusPoint,
          baseRetailBonusPoint,
          listEmployeeIncharge,
          listFee,
          category
        } = res.data.data;
        this.mainModel.name = name;
        this.mainModel.description = description;
        this.mainModel.startDate =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endDate =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.status = status;
        this.mainModel.brand = brand;
        this.mainModel.quantityTarget = quantityTarget;
        this.mainModel.stockMaxTarget = stockMaxTarget;
        this.mainModel.stockMinTarget = stockMinTarget;
        this.mainModel.grossProfitTarget = grossProfitTarget;
        this.mainModel.baseBonusPoint = baseBonusPoint;
        this.mainModel.baseWholesaleBonusPoint = baseWholesaleBonusPoint;
        this.mainModel.baseRetailBonusPoint = baseRetailBonusPoint;
        this.mainModel.listEmployeeIncharge = listEmployeeIncharge.map((element, index) => {
          return {
            ...element,
            count: index + 1
          }
        });
        this.mainModel.listFee = listFee.map((element, index) => {
          return {
            ...element,
            count: index + 1
          }
        });
        this.searchCategoryInput = category.name;

        this.selectedBaseBonusOption = (baseBonusPoint || (!baseWholesaleBonusPoint && !baseRetailBonusPoint)) ? 'basic' : 'advanced';

        return this.makeToastSuccess('Sao chép thành công!');
      });
    },
    onInputBonusPoint: function(input) {      
      if (!input) {
        return;
      }

      if (this.selectedBaseBonusOption === 'basic') {
        this.mainModel.baseWholesaleBonusPoint = 0;
        this.mainModel.baseRetailBonusPoint = 0;
      } else {
        this.mainModel.baseBonusPoint = 0;
      }
    },
    onAddFee: function(input) {
      if (!input.name) {
        return;
      }
      
      const fee = {
        count: this.mainModel.listFee.length === 0 ? 1 : (this.mainModel.listFee[this.mainModel.listFee.length - 1].count + 1),
        name: input.name,
        description: input.description,
        amount: input.amount
      }
      this.mainModel.listFee.push(fee);
      this.creatingFee = {
        name: '',
        description: '',
        amount: 0
      };
    }
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
.row-center-vertical {
  display: flex;
  align-items: center;
}
</style>
